.infositewrap{
  border-left:1px solid #ddd;
}

a,a:link,a:visited{
  text-decoration:none;
}

.infoliste{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
  align-content:center;
  border-bottom:1px solid #ddd;
  padding: 15px 5px 2px 10px;

  label{
    color:#444;
    font-size:80%;
  }
  .sitetext{
    font-size:90%;

  }
  a,a:visited,a:hover{
    outline:unset;

    display:inline-block;


  }
}
.main-col{
  color:#0D2F4A !important;
}
.thumbPreview{
   width:100%;
  @media (orientation: portrait) {
    max-width:110px;
  }
}
.w-44{
  width:47%;
}
.absol{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.effekt{
  @include transition_all(1.7s);
}


.readMorebt{
  display:none;
}

.beschr-text{
   max-height:140px;
  overflow:hidden;

  &.shower{
   max-height:unset;
  }


}

.datlist{

  .forline{

    &:last-child{
      border-bottom:none !important;
    }
  }
}

.reparatur-form-wrapper{
  overflow:hidden;
  height:0;
  @include transition_all(1.7s);
  &.isOpen{
    height:auto;
  max-height:unset;
  }


}

.cal-wrap{
  position:relative;

  .cal-inner{
  position:absolute;
    top:15px;
    left:0;
    right:0;
    padding:10px 5px;
    padding-top:30px;
    padding-bottom:15px;
    background-color:white;
    z-index:999;

  }

  .closecal{
    position:absolute;
    z-index:9999;
    font-size:120%;
    font-weight:200;
    top:-20px;
    right:0;
  }
}
.repatable{
  font-size:80% !important;
  @media (min-width: 600px) {
    font-size:90% !important;
  }

}

.onToppos{
   position:sticky;
  top:0px;
  z-index:9999;

}


.afspbtn{
  position:sticky;
  bottom:10px;
  background-color:white;
}

.bg-greylight{
  background-color:#efefef;
}

.main-color{
  color:#8db724;
}

.statusseleckter{
 button{
   --border:none;
    border:none !important;
  }

}
