@mixin background-cover()
{

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}


@mixin transition_all($sval: 0.50s,$art: all,$timing: ease-in-out) {
  -webkit-transition: $art $sval $timing;
  -moz-transition: $art $sval $timing;
  -o-transition: $art $sval $timing;
  transition: $art $sval $timing;
}



@mixin roundpix($width,$backgroundColor,$borderwidth,$bordercolor)
{
  width: $width;
  height: $width;
  border-radius: $width / 2;
  -webkit-border-radius:  $width / 2;
  -moz-border-radius:  $width / 2;
  overflow:hidden;

  background-color:$backgroundColor;
  margin:0px auto;
  border:$borderwidth $bordercolor solid;
}

@mixin cutword(){
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  -ms-box-sizing:     $type;
  box-sizing:         $type;
}


@mixin rotate($degree: 0deg) {
  -webkit-transform: rotate($degree);
  -moz-transform: rotate($degree);
  -ms-transform:  rotate($degree);
  -o-transform:  rotate($degree);
  transform:  rotate($degree);
}

@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
  background-color: $to;
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background-image: -webkit-linear-gradient(top, $from, $to);
  background-image: -moz-linear-gradient(top, $from, $to);
  background-image: -o-linear-gradient(top, $from, $to);
  background-image: linear-gradient(to bottom, $from, $to);
}

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}
